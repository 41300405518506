<template>
    <div>
         <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="error"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="snackbar" color="#FEBD08">
      <v-layout wrap>
        <v-flex xs11>
          {{ msg }}
        </v-flex>
        <v-flex xs1>
          <v-icon @click="snackbar = !snackbar">mdi-close</v-icon>
        </v-flex>
      </v-layout>
    </v-snackbar>
     <v-layout wrap justify-center>
         <v-flex xs12 text-left py-5 >
          <span style=" font-size: 22px" class="bold"
                > Add Privacy Policy</span>
         </v-flex>
         <!-- <v-flex xs12 text-left class="regular">
             Privacy Policy
         </v-flex> -->
         <!-- <v-flex xs12 text-left>
            <v-text-field outlined v-model="heading">

            </v-text-field>
         </v-flex> -->
         <v-flex xs12 text-left class="regular">
             Description
         </v-flex>
         <v-flex xs12> 
              <v-layout wrap>
            <v-flex>
              <vue-editor
                class="textField2 pt-1"
                v-model="description"
              ></vue-editor>
            </v-flex>
          </v-layout>
         </v-flex>
         <v-flex xs12 sm6 md6 py-6 pb-lg-10 >
            <v-btn @click="addData"  dark tile width="100%" color="error">
                      <span v-if="heading == null && description == null">add</span>
                 <span v-else> update</span> 
            </v-btn>
          </v-flex>
     </v-layout>
    </div>
</template>
<script>
import axios from "axios"
export default {
    data(){
        return{
            heading:null,
            description:null,
            appLoading:false,
            snackbar:false,
            msg:null

        }
    },
    mounted()
    {
       this.getData()
    },
    methods:
    {
        addData()
        {
            axios({
                method:"post",
                url:"/add/privacy/policy",
                 headers: {
                 token: localStorage.getItem("token"),
              },
                data:{
                    // heading:this.heading,
                    // privacypolicy:this.description,
                    privacyPolicy:this.description
                }
            })
            .then((response) =>{
               if(response.data.status) 
               {
                 this.snackbar =true;
                 this.msg= response.data.msg;
               }
               else
               {
                 this.snackbar =true;
                 this.msg= response.data.msg;
               }

            })
        },
         getData()
        {
            axios({
                method:"get",
                url:"/view/privacy/policy",
                 headers: {
                 token: localStorage.getItem("token"),
              },
               
            })
            .then((response) =>{
               if(response.data.status) 
               {
            if(response.data.data.policy)
                    {
                       this.description = response.data.data.policy
                    }
               if(response.data.data.heading)
               {
                 this.heading =response.data.data.heading
               }
               
               }
               else
               {
                 this.snackbar =true;
                 this.msg= response.data.msg;
               }

            })

        }
    }
}
</script>